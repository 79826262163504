import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import intersection from 'lodash/intersection';
import { NotFound } from 'components/error/error';
import {
    isBetaFeaturesEnabled,
    isVideoPlayerHidden,
    isShiftsOnly,
    isUntrusted,
    isProspectMode
} from 'selectors/user/user-features-selector';
import * as roles from 'constants/roles';
import { accessAnalystToAdminSelector } from '../../redux/modules/preferences';
import { isGoalieOnly } from '../../selectors/user/user-features-selector';

export { roles };

function checkRequirements(roles, requiredRoles) {
    return roles && intersection(roles, requiredRoles).length > 0;
}

function getUser(state) {
    if (!state.user || state.user.isProcessing) {
        throw new Error('Components which inject role management must render after user info was fetched');
    }

    return state.user;
}

const authorityMapStateToProps = createSelector(
    getUser,
    isUntrusted,
    isBetaFeaturesEnabled,
    accessAnalystToAdminSelector,
    isShiftsOnly,
    isGoalieOnly,
    isVideoPlayerHidden,
    isProspectMode,
    (
        user,
        untrusted,
        betaFeaturesIsEnabled,
        accessAnalystToAdmin,
        shiftsOnly,
        goalieOnly,
        videoPlayerHidden,
        prospectMode
    ) => {
        const roles = user.account.authorities;
        const { availableProducts } = user;
        const hasAnyRole = requiredRoles => checkRequirements(roles, requiredRoles);
        const hasProduct = product => availableProducts.includes(product);
        return {
            user,
            hasAnyRole,
            hasProduct,
            trusted: !untrusted,
            betaFeaturesIsEnabled,
            accessAnalystToAdmin,
            shiftsOnly,
            goalieOnly,
            videoPlayerHidden,
            prospectMode
        };
    }
);

export const roleManagementProps = {
    // logged in user info
    user: PropTypes.object.isRequired,
    // check if user has one of specified roles
    hasAnyRole: PropTypes.func.isRequired,
    // check if user has specified product
    hasProduct: PropTypes.func.isRequired,
    // true if user trusted, otherwise false
    trusted: PropTypes.bool.isRequired,
    // true if beta-features available for user
    betaFeaturesIsEnabled: PropTypes.bool.isRequired
};

export const injectRoleManagement = component => connect(authorityMapStateToProps)(component);

export function requiredComponent(restriction) {
    return function(Component, fallback = null) {
        // noinspection JSUnusedLocalSymbols omit user prop
        const AuthorityChecker = props => {
            return restriction(props) ? <Component {...props} /> : fallback;
        };

        AuthorityChecker.propTypes = roleManagementProps;

        return connect(authorityMapStateToProps)(AuthorityChecker);
    };
}

export function protectRoute(restriction) {
    return Component => requiredComponent(restriction)(Component, <NotFound />);
}
